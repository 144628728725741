import { spacing, styled, Text } from '@thg-commerce/enterprise-theme'
import { Button } from '@thg-commerce/gravity-elements'
import { MarginValue } from '@thg-commerce/gravity-theme/margin'

import { AccessibilityButton } from '../../styles'

export const Title = styled.h2`
  ${(props) =>
    Text(
      props.theme.widget.productList.verticalFacets?.title?.textStyle.entry ||
        'medium1',
      props.theme.widget.productList.verticalFacets?.title?.textStyle.style ||
        'alternate',
    )};
`

export const ClearAllButton = styled(Button)`
  margin-top: ${spacing(1)};
`

export const FacetsContainer = styled.div`
  margin-top: ${(props) =>
    spacing(
      props.theme.widget.productList.verticalFacets?.container?.marginTop || 3,
    )};
`

export const AccordionContainer = styled.div<{ marginTop?: MarginValue }>`
  &:not(:first-child) {
    margin-top: ${(props) =>
      spacing(
        props.theme.widget.productList.verticalFacets?.accordion?.marginTop ||
          4,
      )};
  }
`

export const StyledAccessibilityButton = styled(AccessibilityButton)`
  margin-top: ${spacing(2)};
`
