import * as React from 'react'
import handleViewport from 'react-in-viewport'

import { useSiteConfig } from '@thg-commerce/enterprise-core'
import { Accordion } from '@thg-commerce/gravity-system'
import { AccordionBorderPlacement } from '@thg-commerce/gravity-system/components/Accordion'
import { MarginValue } from '@thg-commerce/gravity-theme/margin'

import { facetsCallbacks } from '../../dataTracking'
import { Focus } from '../../ProductListPage'
import { FacetContent } from '../FacetContent/FacetContent'
import { SelectedFacets } from '../SelectedFacets/SelectedFacets'
import { Facet, FacetDataLayerSchema, FacetsProps, FacetType } from '../types'
import { displayFacet } from '../utils'

import {
  AccordionContainer,
  ClearAllButton,
  FacetsContainer,
  StyledAccessibilityButton,
  Title,
} from './styles'

export interface VerticalFacetsProps extends FacetsProps {
  i18nText: {
    refine: string
    clearAll: string
    searchBrandLabel: string
    searchBrandPlaceholder: string
    goToProductSection: string
    minimumLabel: string
    maximumLabel: string
    sliderHeader: string
  }
  focusedElement?: Focus | null
  verticalFacetsTitleRef?: React.RefObject<HTMLHeadingElement>
  onAccessibilityButtonClick: () => void
  onAccessibilityButtonBlur: () => void
  marginTop?: MarginValue
  openAllFacets?: boolean
  facetSchema: FacetDataLayerSchema
}

const AccordionInView = ({
  inViewport,
  enterCount,
  forwardedRef,
  facetType,
  facetProps,
  shouldCloseWhenNotVisible,
  facetSchema,
}: RenderFacetProps & {
  inViewport: boolean
  enterCount: number
  forwardedRef: React.Ref<any>
}) => {
  return inViewport || enterCount > 0 ? (
    <RenderFacet
      facetType={facetType}
      facetProps={facetProps}
      shouldCloseWhenNotVisible={shouldCloseWhenNotVisible}
      forwardedRef={forwardedRef}
      facetSchema={facetSchema}
    />
  ) : (
    <div ref={forwardedRef}></div>
  )
}

interface RenderFacetProps {
  facetType: FacetType
  facetProps: VerticalFacetsProps
  shouldCloseWhenNotVisible?: boolean
  forwardedRef?: React.Ref<any>
  facetSchema: FacetDataLayerSchema
}

const RenderFacet = ({
  facetType,
  facetProps,
  forwardedRef,
  shouldCloseWhenNotVisible,
  facetSchema,
}: RenderFacetProps) => {
  const { useGA4EnhancedEcom } = useSiteConfig()

  return (
    <AccordionContainer
      data-testid={`vertical-facet-${facetType.facetName}`}
      key={`verticalfacet-${facetType.facetName}`}
      ref={forwardedRef}
      marginTop={facetProps.marginTop}
    >
      <Accordion
        isOpen={!shouldCloseWhenNotVisible}
        id={`facet-${facetType.facetName}`}
        title={facetType.facetHeader}
        textEntry="small"
        borderPlacement={AccordionBorderPlacement.BOTTOM}
        theme={{
          title: {
            fontStyle: {
              entry: 'small',
              style: 'alternate',
              textDecoration: 'none',
              transform: 'none',
            },
            padding: {
              top: 1,
              bottom: 1,
              right: 1,
              left: 1,
            },
          },
          content: {
            padding: {
              top: 0,
              right: 1,
              bottom: 0,
              left: 1,
            },
          },
        }}
      >
        <FacetContent
          facet={facetType}
          selectedFacets={facetProps.selectedFacets}
          onInputChange={facetProps.onInputChange}
          maxHeight="216px"
          i18nText={{
            searchBrandLabel: facetProps.i18nText.searchBrandLabel,
            searchBrandPlaceholder: facetProps.i18nText.searchBrandPlaceholder,
            maximumLabel: facetProps.i18nText.maximumLabel,
            minimumLabel: facetProps.i18nText.minimumLabel,
            sliderHeader: facetProps.i18nText.sliderHeader,
          }}
          useGA4EnhancedEcom={useGA4EnhancedEcom}
          facetSchema={facetSchema}
        />
      </Accordion>
    </AccordionContainer>
  )
}

export const VerticalFacets = React.memo((props: VerticalFacetsProps) => {
  const facets = props.facets.reduce<React.ReactElement[]>(
    (accumulator, facet, index) => {
      if ('options' in facet && facet.options.length === 0) {
        return accumulator
      }

      const AccordionInViewComponent = handleViewport<RenderFacetProps>(
        AccordionInView,
      )

      accumulator.push(
        (facet.__typename === Facet.SLIDER ||
          ((facet.__typename === Facet.SIMPLE ||
            facet.__typename === Facet.RANGED) &&
            displayFacet(facet.options))) &&
          (props.openAllFacets || index < 2) ? (
          <RenderFacet
            facetType={facet}
            facetProps={props}
            facetSchema={props.facetSchema}
          />
        ) : (
          <AccordionInViewComponent
            facetType={facet}
            facetProps={props}
            shouldCloseWhenNotVisible={true}
            facetSchema={props.facetSchema}
          />
        ),
      )

      return accumulator
    },
    [],
  )

  return (
    <React.Fragment>
      <Title
        data-testid="vertical-facets-title"
        tabIndex={props.focusedElement === Focus.VERTICAL_FACETS_TITLE ? 0 : -1}
        onBlur={props.onAccessibilityButtonBlur}
        ref={props.verticalFacetsTitleRef}
      >
        {props.i18nText.refine}
      </Title>
      <StyledAccessibilityButton
        data-testid="go-to-product-section-button"
        emphasis="medium"
        sizing="regular"
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            props.onAccessibilityButtonClick()
          }
        }}
        onClick={props.onAccessibilityButtonClick}
      >
        {props.i18nText.goToProductSection}
      </StyledAccessibilityButton>
      {props.selectedFacets.length > 0 && (
        <React.Fragment>
          <ClearAllButton
            data-testid="desktop-facets-clear-all-button"
            emphasis="low"
            onClick={React.useCallback(() => {
              props.onInputChange({
                facets: [],
              })
              facetsCallbacks.clearAllClicked()
            }, [props.onInputChange, facetsCallbacks])}
          >
            {props.i18nText.clearAll}
          </ClearAllButton>
          <SelectedFacets
            facets={props.facets}
            selectedFacets={props.selectedFacets}
            onInputChange={props.onInputChange}
            i18nText={props.i18nText}
          />
        </React.Fragment>
      )}
      <FacetsContainer>{facets}</FacetsContainer>
    </React.Fragment>
  )
})
