import * as React from 'react'

import { Button } from '@thg-commerce/gravity-elements'
import {
  ButtonEmphases,
  ButtonSizes,
} from '@thg-commerce/gravity-elements/Button/Button'
import {
  CarouselButtonStyle,
  HorizontalScrollWrapper,
} from '@thg-commerce/gravity-patterns/HorizontalScrollWrapper'
import { withPrefetch } from '@thg-commerce/gravity-system/prefetch'
import { TextEntry } from '@thg-commerce/gravity-theme'
import { HorizontalAlignment } from '@thg-commerce/gravity-theme/alignments'

import { StyledListItem, StyledWrapper, Title } from './styles'

export interface ProductCategoryItemProps {
  text: string
  href: string
  size?: TextEntry
  colour?: string
}
export interface ProductCategoriesProps {
  categories: ProductCategoryItemProps[]
  className?: string
  title?: string
  chevronColour?: string
  box?: {
    stroke: number
    colour: string
  }
  backgroundColour: string
  alignment: HorizontalAlignment
  button: {
    sizing: ButtonSizes
    emphasis: ButtonEmphases
  }
  disableHorizontalScroll: boolean
  hideCarouselButtonOnMobile?: boolean
  enableScrollableList?: boolean
}

const PrefetchButton = withPrefetch('href', Button)

export const ProductCategories = (props: ProductCategoriesProps) => {
  const categoryItems = props.categories.map((category, index) => {
    return (
      <StyledListItem
        tabIndex={category.href ? -1 : 0}
        hasUrl
        key={`product-category-${index}`}
        data-testid={`category-item-${index}`}
      >
        <PrefetchButton
          emphasis="medium"
          sizing="regular"
          renderedAs="a"
          href={category.href}
        >
          {category.text}
        </PrefetchButton>
      </StyledListItem>
    )
  })

  if (props.disableHorizontalScroll) {
    return (
      <div className={props.className}>
        {props.title && <Title>{props.title}</Title>}
        <StyledWrapper alignment={props.alignment}>
          {categoryItems}
        </StyledWrapper>
      </div>
    )
  }

  return (
    <div className={props.className}>
      {props.title && <Title>{props.title}</Title>}
      <HorizontalScrollWrapper
        alignment={props.alignment}
        gap={2}
        backgroundColour={props.backgroundColour}
        buttonStyle={CarouselButtonStyle.GRADIENT}
        hideCarouselButtonOnMobile={props.hideCarouselButtonOnMobile}
        enableScrollableList={props.enableScrollableList}
      >
        {categoryItems}
      </HorizontalScrollWrapper>
    </div>
  )
}
